<template>
  <div class="must-read__wrapper opened-bracket closed-bracket">
    <p class="sidebar__section-title title">Must Read</p>
    <ul class="must-read__news-list sidebar__section-content">
      <li
        v-for="item in mustReadItems"
        :key="item.id"
        :class="getlistItemClass(item)"
      >
        <client-only>
          <div v-if="item.isSponsored" class="must-read__sponsored-label">
            Sponsored
          </div>
        </client-only>
        <a-link
          :to="generateArticlePath(item.article)"
          :trailing-slash="!item.article.isMigrated"
          >{{ item.article.title }}</a-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ALink from 'shared/ALink'
import mixins from '@/utils/mixins'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'

export default {
  name: 'MustRead',
  mixins: [mixins.urlGenerators, serverCacheKey],
  components: { ALink },
  computed: {
    ...mapGetters({
      mustReadItems: 'sidebar/mustReadItems'
    })
  },
  methods: {
    getlistItemClass({ isHighlighted }) {
      return [
        'must-read__news-item',
        {
          'must-read__news-item--highlighted': isHighlighted
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.must-read__wrapper {
  width: 100%;

  .must-read__title {
    padding-left: 29px;
  }

  .must-read__news-list {
    margin-top: 8px;

    @include sidebar-small-size {
      margin-top: 0;
    }
  }

  .must-read__news-item {
    overflow: hidden;
    font-family: $font-body;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    padding: 10px;
    border-bottom: 1px solid $c--gray-secondary;

    &:last-child {
      border-bottom: none;
    }

    &--highlighted {
      background: rgb(229, 245, 250);
      font-weight: 400;
    }
  }

  .must-read__sponsored-label {
    text-align: right;
    font-family: $font-sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
}
</style>
